<template>
  <div class="container">
    <div class="houses__wrapper screen__wrapper">
      <Card v-for="house in houses" :key="house.id" :obj="house" @click.native="info(house)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import Card from '../Card'
import InfiniteLoading from 'vue-infinite-loading'
import Info from '../Info'

export default {
  name: 'Houses',
  components: {
    Card,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    houses() {
      return this.$store.getters['objects/houses/houses']
    },
    hasMoreResults() {
      return this.houses.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['objects/houses/pagination'].total
    },
    page() {
      return this.$store.getters['objects/houses/page']
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      const page = this.page
      try {
        await this.$store.dispatch('objects/houses/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(obj) {
      this.$modal.display(Info, {info: obj}, {name: 'InfoObject'})
    }
  },
  // beforeDestroy() {
  //   if (
  //     Object.keys(this.$store.getters.sortParams).length !== 0 ||
  //     Object.keys(this.$store.getters.filterParams).length !== 0
  //   ) {
  //     this.$store.commit('sortParams', {})
  //     this.$store.commit('filterParams', {})
  //     this.$store.commit('resetHouses')
  //     this.loadObj()
  //   }
  //   this.$store.commit('filterParamsAll', {})
  //   this.$store.commit('filterParamsObjectsActive', 0)
  // },
}
</script>